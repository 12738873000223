* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  width: 100%;
  scroll-behavior: smooth;
}
body {
  width: 100%;
  height: auto;
  overflow-x: auto;
  background: var(--gray);
}
@font-face {
  font-family: "Helvetica";
  src: url(../font/Helvetica.ttf);
}
:root {
  --primary: #2877ff;
  --secondary: #75d526;
  --gray: #efefef;
  --radius: 5px;
  --transition: all 0.5s ease-in-out;
}
h1 {
  font-size: 1.5rem;
  margin: 0;
  font-family: "Helvetica";
  text-transform: capitalize;
}
p,
a,
li,
label,
select,
input,
textarea,
button {
  font-size: 1rem;
  margin: 0;
  font-family: "Helvetica";
}
.form-container {
  border-radius: var(--radius);
  background: #fff;
  max-width: 576px;
  margin: 0 auto;
}
label {
  text-transform: capitalize;
  display: block;
}
.input {
  width: 100%;
  padding: 5px;
  border-radius: var(--radius);
  outline: none;
  border: 1px solid var(--gray);
  transition: var(--transition);
}
.input:focus {
  border: 1px solid var(--primary);
}
.button {
  text-align: center;
  border: none;
  outline: none;
  background: var(--primary);
  color: #fff;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: var(--radius);
  transition: var(--transition);
}
.button:hover {
  background: var(--secondary);
}
nav {
  background: var(--primary);
}
.logo {
  width: 200px;
  height: auto;
  background: #fff;
  padding: 0 4px 4px;
  border-radius: var(--radius);
}
img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
